import React, { useState, useEffect } from 'react';
import { Paper, Box, TableBody, TableRow, TableCell, InputAdornment, Grid } from '@mui/material';
import { ActionButton, TextInput } from "../components/common/inputs";
import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAppContext';
import StoreService from "../services/StoreService";
import { useTable, PageHeader, Notification, ConfirmDialog } from '../components/common';
import { useStyles } from './Redflags/styles';
import notifyError from '../errors/notifyError';

const headCells = [
    { id: 'storeName', label: 'Store Name' },
    { id: 'website', label: 'Website' },
    { id: 'email', label: 'Owner Email' },
    { id: 'actions', label: 'Actions', disableSorting: true }
]

export default function Stores() {
    const classes = useStyles();
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        StoreService.getStores(axiosPrivate)
            .then(response => {
                isMounted && setRecords(response.data.stores);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate, auth]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(records, headCells, filterFn);


    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value === "")
                    return items;
                else
                    return items.filter(x => x.storeName ? x.storeName.toLowerCase().includes(target.value.toLowerCase()) : false)
            }
        })
    }

    const handleDeleteStoreInfo = async id => {
        try {
            await StoreService.removeStoreInfo(axiosPrivate, id);

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            setNotify({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        } catch (err) {
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            notifyError(err, setNotify, navigate, location);
        }
    }


    const handleDeleteStoreAccount = async id => {
        try {
            await StoreService.removeStoreAccount(axiosPrivate, id);

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            const newRecords = records.filter((item) => item._id !== id);
            setRecords(newRecords);
            setNotify({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        } catch (err) {
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            notifyError(err, setNotify, navigate, location);
        }
    }

    return (
        <>
            <PageHeader
                title="Stores"
            />

            <Paper className={classes.pageContent}>
                <Box sx={{
                    minWidth: 700,
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                label="Search Stores"
                                size="small"
                                className={classes.searchInput}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>)
                                }}
                                onChange={handleSearch}
                            />
                        </Grid>
                    </Grid>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item._id}>
                                    <TableCell>{item.storeName}</TableCell>
                                    <TableCell>{item.website}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>
                                        <ActionButton
                                            color="warning"
                                            disabled={auth.user.role !== "admin_user"}
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: `Are you sure to delete info (orders and redflags) of this store ${item.storeName}?`,
                                                    subTitle: "You can't undo this operation",
                                                    onConfirm: () => handleDeleteStoreInfo(item._id)
                                                })
                                            }}
                                        >
                                            Delete Info
                                        </ActionButton>
                                        <ActionButton
                                            color="secondary"
                                            disabled={auth.user.role !== "admin_user"}
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: `Are you sure to cancel the account of this store ${item.storeName}?`,
                                                    subTitle: "You can't undo this operation",
                                                    onConfirm: () => handleDeleteStoreAccount(item._id)
                                                })
                                            }}
                                        >
                                            Cancel Account
                                        </ActionButton>
                                    </TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                </Box>
            </Paper>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}