import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, Button, Typography, Grid, Link, InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { AuthService } from '../../services/AuthService';
import { makeStyles } from '@mui/styles';
import useAuth from '../../hooks/useAppContext';
import useInput from '../../hooks/useInput';
import useAdminRefreshTokenLikeLogin from '../../hooks/useAdminRefreshTokenLikeLogin';
import { setLogin, isLogin, clearSearch, clearLogin } from '../../browser/localStorage';
import displayError from '../../errors/displayError';
import AdminVerifyDeviceOtp from "../../components/public/AdminVerifyDeviceOtp";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    middle: {
        textAlign: 'center',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function Login() {
    const adminRefreshLikeLogin = useAdminRefreshTokenLikeLogin();
    const { setAuth } = useAuth();
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    let from = location.state?.from?.pathname || '/orders';
    //point: prevent deadloop of infinite transfer between
    // /logout->/login->/logout
    if (from === "/logout")
        from = "/orders";

    const emailRef = useRef();
    const [email, resetEmail, emailAttribs] = useInput('user', ''); // useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [eye, setEye] = useState(false);
    const [isVerifyingDevice, setIsVerifyingDevice] = useState(false);

    const handleEye = () => {
        setEye(!eye);
    }

    useEffect(() => {
        // setAuth({}) take some clocks for the auth to cleared, which may cause dead loop for some time
        // only check auth during the load mode inside BigCommerce
        if (isLogin()) {
            const verifyRefreshToken = async () => {
                try {
                    await adminRefreshLikeLogin();
                    navigate(from, { replace: true });
                }
                catch (err) {
                    clearLogin();
                    clearSearch();
                    // displayError(err, setErrMsg); // No need to show users that the refresh token expires
                }
            }

            verifyRefreshToken();
        }
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) return;

        AuthService.adminLogin(email, password)
            .then(response => {
                setPassword('');

                if (response.data.msg === 'Please check your email for one time passcode') {
                    setAuth({});
                    setIsVerifyingDevice(true);
                }
                else {
                    setAuth({ ...(response.data) });
                    setLogin();
                    navigate(from, { replace: true });
                }
            })
            .catch(err => {
                displayError(err, setErrMsg);
            });
    };


    return (
        isVerifyingDevice ?
            <AdminVerifyDeviceOtp email={email} /> :
            <>
                <p className={errMsg ? "alert-danger login-container" : "offscreen login-container"} aria-live="assertive" >{errMsg}</p >
                <h1>Login</h1>
                <form className="login-container" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                ref={emailRef}
                                type='email'
                                label="Email"
                                variant="outlined"
                                size="small"
                                sx={{
                                    width: '100%',
                                }}
                                {...emailAttribs}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                type={eye ? "text" : "password"}
                                label="Password"
                                variant="outlined"
                                size="small"
                                value={password}
                                sx={{
                                    width: '100%'
                                }}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleEye}>
                                                {eye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.middle}>
                            <Button
                                type='submit'
                                variant="contained"
                                sx={{
                                    width: '100%'
                                }}>
                                Log In
                            </Button>
                        </Grid>

                        <Grid item xs={6} className={classes.middle}>
                            <Link href="/user/forgot_password" sx={{
                                display: 'inline-block',
                                mx: 'auto',
                                mt: 1,
                                width: '80%',
                                textDecoration: 'none'
                            }}>Forgot Password</Link>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography
                                sx={{
                                    width: '100%'
                                }}
                            >Don't have an account?
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Link href="/register" sx={{
                                display: 'inline-block',
                                mx: 'auto',
                                mt: 0.5,
                                width: '80%',
                                textDecoration: 'none'
                            }}>Create one</Link>
                        </Grid>
                    </Grid>

                </form>
            </>
    );
}

export default Login;
