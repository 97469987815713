import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import "./App.css";

import AdminMiniDrawer from "./components/drawer/AdminMiniDrawer";
import AdminProfile from "./views/AdminProfile";
import Billing from "./views/Settings/Billing";
import Cards from "./views/Settings/Cards";
import CheckoutForm from './views/Settings/CheckoutForm';
import { Cancel } from './components/stripe';
import Users from "./views/Settings/Users";
import General from './views/Settings/General';

import Orders from "./views/Orders/AllOrders";
import FraudOrders from "./views/Orders/FraudOrders";
import FlaggedOrders from "./views/Orders/FlaggedOrders";

import EmailRedFlags from "./views/Redflags/EmailRedFlags";
import AddrRedFlags from "./views/Redflags/AddrRedFlags";
import IpRedFlags from "./views/Redflags/IpRedFlags";
import PhoneRedFlags from "./views/Redflags/PhoneRedFlags";

import ManuBar from "./components/ManuBar";
import AdminLogin from './views/Public/AdminLogin';
import Logout from './views/Logout';
import AdminRegister from './views/Public/AdminRegister';
import AdminForgotPasswordAllInOne from "./views/Public/AdminForgotPasswordAllInOne";
import ResetPassword from "./views/Public/OldResetPassword";
import AdminVerifyEmail from "./views/Public/AdminVerifyEmail";

import Unauthorized from "./views/Unauthorized";
import Error from './views/Error';
import RequireAuth from "./views/RequireAuth";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import AdminPersistLogin from "./views/AdminPersistLogin";
import { useLogoutAllTabs } from './hooks/useBroadcast';
import Stores from './views/Stores';

function App() {
  const theme = createTheme();
  const logoutAllTabs = useLogoutAllTabs();

  useEffect(() => {
    logoutAllTabs();
  }, [logoutAllTabs])

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/" element={<ManuBar />} >
            <Route index element={<AdminLogin />} />
            <Route path="index.html" element={<AdminLogin />} />
            <Route path="login" element={<AdminLogin />} />
            <Route path='register' element={<AdminRegister />} />

            <Route path='user/verify_email' exact element={<AdminVerifyEmail />} />
            <Route path='user/forgot_password' exact element={<AdminForgotPasswordAllInOne />} />

            {/* Following is used by forgot password + reset password ( version 1 ) */}
            <Route path='user/reset_password' exact element={<ResetPassword />} />
          </Route>

          {/* we want to protect these routes*/}
          <Route element={<AdminPersistLogin />}>
            <Route element={<RequireAuth allowedRoles={['admin_user', 'owner', 'admin', 'user']} />} >
              <Route path="/orders" element={<AdminMiniDrawer />} >
                <Route index element={<Orders />} />
                <Route path="fraud" element={<FraudOrders />} />
                <Route path="flagged" element={<FlaggedOrders />} />
              </Route>

              <Route path="/manage" element={<AdminMiniDrawer />} >
                <Route path="unauthorized" element={<Unauthorized />} />
              </Route>

              <Route path="/rules" element={<AdminMiniDrawer />} >
                <Route index element={<EmailRedFlags />} />
                <Route path="email" element={<EmailRedFlags />} />
                <Route path="address" element={<AddrRedFlags />} />
                <Route path="ip" element={<IpRedFlags />} />
                <Route path="phone" element={<PhoneRedFlags />} />
              </Route>

              <Route path="/settings" element={<AdminMiniDrawer />} >
                <Route index element={<General />} />
                <Route path='users' element={<Users />} />
                <Route path='general' element={<General />} />
              </Route>

              <Route path="/stores" element={<AdminMiniDrawer />} >
                <Route index element={<Stores />} />
              </Route>

              {/* <Route path="/tickets" element={<AdminMiniDrawer />} >
                <Route index element={<Tickets />} />
              </Route> */}

              <Route path="/profile" element={<AdminMiniDrawer />} >
                <Route index element={<AdminProfile />} />
              </Route>

              <Route path="/logout" element={<Logout />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={['admin_user', 'owner', 'admin']} />} >
              <Route path="/settings" element={<AdminMiniDrawer />} >
                <Route path='billing' element={<Billing />} />
                <Route path='subscribe' element={<CheckoutForm />} />
                <Route path='payment_methods' element={<Cards />} />
              </Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={['admin_user']} />} >
              <Route path="/settings" element={<AdminMiniDrawer />} >
                <Route path='cancel' element={<Cancel />} />
              </Route>
            </Route>

          </Route>

          {/* Catch all */}
          <Route path='*' element={<Error />} />

        </Routes>
      </div>
    </ThemeProvider>

  );
}


export default App;
